<template>
  <v-app>
    <v-container fluid class="my-4">
      <v-layout wrap align-start justify-center row fill-height>
        <v-flex xs12 md10 lg6 class="py-2 card">
          <v-card>
            <v-card-title primary-title class="px-5 pt-5">
              <div class="subheading secondary--text my-3">
                February 20, 2025
              </div>
              <h3 class="display-1">
                BugSeq Bioinformatics Joins RANGER Consortium to Develop Rapid Metagenomic Diagnostic for Respiratory Viruses
              </h3>
            </v-card-title>
            <v-card-text class="px-5 pt-4">
              <p>
                <a href="https://bugseq.com" target="_blank">BugSeq Bioinformatics Inc.</a>, a leader in clinical and public health bioinformatics, today announced its participation in the RApid Next Generation Sequencing for Effective Medical Response (RANGER) consortium, led by Ginkgo Bioworks. This groundbreaking initiative aims to develop a rapid, metagenomic next-generation sequencing (mNGS) solution that enables hospitals and healthcare facilities to achieve same-day diagnostics for respiratory viruses.
              </p>
              <p>
                As part of this effort, Ginkgo Bioworks and its consortium partners—including BugSeq, Jumpcode, TGEN, and Planet Innovation—are eligible to receive up to €24 million in funding over the next four years. This support is provided through the European Commission’s Health Emergency Preparedness and Response Authority (HERA) budget under the EU4Health program.
              </p>
              <p>
                <div class="font-weight-bold mb-2">Advancing Infectious Disease Diagnostics</div>
                With the ever-evolving landscape of infectious disease threats, the ability to rapidly detect and characterize pathogens is critical for both patient care and public health response. Metagenomic NGS (mNGS) has transformed pathogen identification, offering comprehensive, unbiased detection of infectious agents without requiring prior assumptions about the causative organism. However, for NGS to fully realize its potential in clinical and epidemiological settings, scalable, regulatory-compliant, and end-to-end solutions are essential.
              </p>
              <p>
                The RANGER project seeks to revolutionize diagnostic capabilities by equipping clinicians with a cutting-edge, fully automated benchtop device that streamlines sample preparation and delivers results in just six hours. This rapid turnaround will empower hospitals with critical decision-making flexibility—enabling them to triage patients efficiently, reduce unnecessary isolation periods, and minimize the need for empirical therapies and prolonged hospital stays.
              </p>
              <p>
                <div class="font-weight-bold mb-2">Clinical Validation and Future Deployment</div>
                BugSeq will play a key role in integrating and validating its clinical metagenomic bioinformatics platform within the benchtop device. The project will progress through rigorous clinical validation in collaboration with UZ Leuven (Belgium), Karolinska University Hospital (Sweden), and Tartu University Hospital (Estonia). In its final phase, the consortium will work toward securing EU certification under the In Vitro Diagnostic Regulation (IVDR).
              </p>
              <p>
                <div class="font-weight-bold mb-2">Strengthening Global Health Security</div>
                This program aligns with HERA’s mission to enhance Europe’s capacity to prevent, detect, and respond swiftly to cross-border health emergencies by increasing the availability of critical medical countermeasures. Through its participation in the RANGER consortium, BugSeq is contributing to an innovative and scalable solution that will make rapid, metagenomic sequencing more accessible for healthcare systems worldwide. 
              </p>
              <p>
                <div class="font-weight-bold mb-2">Quotes from HERA and RANGER Consortium Partners</div>
                “Diagnostics as decision making tools are a key component of our toolbox to rapidly respond to health emergencies. The ability to quickly identify even previously unknown pathogens will be a critical step in reinforcing the EU’s collective health resilience,” said Laurent Muschel, Acting Director-General of HERA.
              </p>
              <p>
                “BugSeq is thrilled to partner with RANGER consortium members to deploy our clinically-tailored bioinformatics platform to advance the use of rapid metagenomic sequencing for infectious disease diagnostics," said Sam Chorlton, CEO of BugSeq. "This collaborative effort to develop a seamless, end-to-end solution marks a significant step forward in accelerating the adoption of agnostic diagnostics and strengthening our ability to respond swiftly to public health emergencies."  
              </p>
              <p>
                “This collaboration with HaDEA embodies our mission at Ginkgo Biosecurity to arm global healthcare systems with cutting-edge capabilities,” said Matt McKnight, General Manager of Ginkgo Biosecurity. “Instead of waiting days to confirm a diagnosis, we want doctors to be able to run a sample through this machine and get results in about 6 hours. Ultimately, this means faster triage, better patient outcomes, and a more resilient frontline response to both seasonal viruses and future pandemics.”
              </p>
              <p>
                “We’re moving toward a diagnostic model that’s as easy and quick as scanning a patient’s sample right on the spot,” added Dr. Nita Madhav, Head of Epidemiology & Global Risk Analytics at Ginkgo Bioworks. “The European Commission’s decision to fund this effort not only accelerates access to innovative medical countermeasures but also enables a step-change in how we detect and respond to novel respiratory pathogens. Our integrated approach—incorporating metagenomic sequencing, computational design, and predictive analytics—will help clinicians and public health leaders stay ahead of the curve.”
              </p>
              <p>
                “HaDEA is committed to fostering innovative solutions that can strengthen Europe’s capacity to prevent, detect, and respond to biological threats,” said Marina Zanchi - Director at HADEA. “This project represents a leap forward in leveraging next-generation sequencing at the bedside, and we believe the collaborative approach taken by Ginkgo and its partners will greatly accelerate the path toward safer, more prepared healthcare systems across the region.”
              </p>
              <p>
                “We’re excited to be part of the RANGER consortium, which in partnership with Jumpcode’s CRISPR-based technology, represents a significant advancement in diagnostic capabilities. By enabling faster and more comprehensive pathogen detection through our Cipher system, we're taking an important step forward in improving clinical diagnostics and enhancing pandemic preparedness,” said Mike Salter, CEO of Jumpcode Genomics.
              </p>
              <p>
                “Planet Innovation is proud to be a part of the RANGER consortium, and will leverage its large product development and manufacturing capabilities in developing the highly innovative Cipher system. Planet Innovation has extensive experience in developing world-class platforms and we look forward to working alongside Jumpcode Genomics and all the consortium members in delivering a diagnostic solution that provides better patient outcomes, and a more resilient frontline response to both seasonal viruses and future pandemics,” said Stuart Elliott, CEO of Planet Innovation.
              </p>
              <p>
                For more details, visit the <a href="https://biosecurity.ginkgo.bio/resources/blog/were-partnering-with-hadea-in-an-up-to-eur24-million-consortium-project-to-deliver-next-generation-agnostic-diagnostics-for-respiratory-viruses-at-the-point-of-care" target="_blank">official press release</a> for the program.
              </p>
              <p>
                <div class="font-weight-bold mb-2">About BugSeq</div>
                BugSeq is leveraging advanced sequencing technologies to revolutionize infectious disease testing and pathogen surveillance. BugSeq's analysis suite delivers unparalleled data analytics and industry-leading expertise to convert sequencing data into actionable results. BugSeq aims to streamline the interpretation of complex sequencing data and enhance diagnostic workflows across various settings, including hospitals, public health agencies, and other healthcare settings. By profiling tens of thousands of microorganisms and markers of antimicrobial resistance from any specimen, BugSeq enables broad adoption of sequencing to improve health.
              </p>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
export default {
  metaInfo: {
    title:
      "BugSeq announces second BARDA DRIVe award to develop AI-driven bioinformatic reporting for agnostic diagnostic sequencing assays",
    titleTemplate: "",
  },
};
</script>

<style scoped>
p {
  margin-bottom: 36px;
}
</style>
