<template>
  <v-card class="pa-4">
    <p class="home-section-title">Conferences</p>
    <p>
      BugSeq will be attending/sponsoring the following conferences. If you will be attending any of these, please <a href="mailto:contact@bugseq.com?subject=Conference Inquiry&body=Hi! I'd like to meet up at a conference you will be attending...">reach out</a> as we would love to schedule time to meet.
    </p>
    <v-container
      v-for="(conference, index) in conferences"
      :key="index"
    >
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <a :href="conference.href" target="_blank">
            <v-img
              :src="conference.image"
              class="conferenceimg"
            ></v-img>
          </a>
        </v-flex>
        <v-flex xs12 sm9 class="pl-4">
          <div class="title mb-3">{{conference.name}}</div>
          <div class="my-2"><v-icon class="mr-2">date_range</v-icon>{{conference.dates}}</div>
          <div class="my-2"><v-icon class="mr-2">place</v-icon>{{conference.location}}</div>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import ImgAPHLIdCon from "@bugseq-site/www/src/assets/img/resources/conferences/2025/idcon.jpg";
import ImgESCMID from "@bugseq-site/www/src/assets/img/resources/conferences/2025/escmid.jpg";
import ImgAMMI from "@bugseq-site/www/src/assets/img/resources/conferences/2025/ammi.png";
import ImgAPHL from "@bugseq-site/www/src/assets/img/resources/conferences/2025/aphl.jpg";
import ImgSFAF from "@bugseq-site/www/src/assets/img/resources/conferences/2025/sfaf.png";
import ImgLC from "@bugseq-site/www/src/assets/img/resources/conferences/2025/lc.jpg";
import ImgASM from "@bugseq-site/www/src/assets/img/resources/conferences/2025/asm.jpg";
import ImgADLM from "@bugseq-site/www/src/assets/img/resources/conferences/2025/adlm.jpg";

export default {
  metaInfo: {
    title: "Conferences",
    titleTemplate: "BugSeq | %s",
  },
  data() {
    return {
      conferences: [
        {
          name: "APHL ID Lab Con 2025",
          dates: "March 25-27, 2025",
          location: "Pasadena, CA",
          image: ImgAPHLIdCon,
          href: "https://www.aphl.org/conferences/ID-Lab-Con/Pages/default.aspx",
        },
        {
          name: "ESCMID Global",
          dates: "April 11-15, 2025",
          location: "Vienna, AT",
          image: ImgESCMID,
          href: "https://www.escmid.org/congress-events/escmid-global/vienna-2025/",
        },
        {
          name: "AMMI",
          dates: "April 29-May 2, 2025",
          location: "Calgary, CA",
          image: ImgAMMI,
          href: "https://ammi-cacmidconference.ca/",
        },
        {
          name: "APHL",
          dates: "May 5-8, 2025",
          location: "Portland, OR",
          image: ImgAPHL,
          href: "https://www.aphl.org/conferences/annualmeeting/Pages/default.aspx",
        },
        {
          name: "SFAF",
          dates: "May 20-22, 2025",
          location: "Sante Fe, NM",
          image: ImgSFAF,
          href: "https://www.sfafmeeting.org/",
        },
        {
          name: "London Calling",
          dates: "May 20-23, 2025",
          location: "London, UK",
          image: ImgLC,
          href: "https://nanoporetech.com/about/events/conferences/lc25",
        },
        {
          name: "ASM Microbe",
          dates: "June 19-23, 2025",
          location: "Los Angeles, CA",
          image: ImgASM,
          href: "https://asm.org/events/asm-microbe/home",
        },
        {
          name: "ADLM 2025",
          dates: "July 27-31, 2025",
          location: "Chicago, IL",
          image: ImgADLM,
          href: "https://meeting.myadlm.org/",
        },
      ],
    }
  },
};
</script>

<style scoped>
.conferenceimg {
  border-radius: 5px;
}
</style>
