import { render, staticRenderFns } from "./PressRelease-2025-02-20-hadea.vue?vue&type=template&id=77c05196&scoped=true"
import script from "./PressRelease-2025-02-20-hadea.vue?vue&type=script&lang=js"
export * from "./PressRelease-2025-02-20-hadea.vue?vue&type=script&lang=js"
import style0 from "./PressRelease-2025-02-20-hadea.vue?vue&type=style&index=0&id=77c05196&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77c05196",
  null
  
)

export default component.exports